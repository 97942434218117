import React from "react";
import { serverSideTranslations } from "next-i18next/serverSideTranslations";

import FooterContainer from "../../containers/Footer";
import { Button } from "react-bootstrap";
import { useLinkTranslation } from "../../custom-hooks/useLinkTranslation";

const ErrorPage = () => {
	const { RouterLink } = useLinkTranslation();

	return (
		<div className="ErrorPage">
			<div className="ErrorPage__main-component">
				<div className="image-bg" />
				<h1>This page does not seem to exist…</h1>
				{/* <p>Please check if the URL is correct</p>
        <RouterLink href='/dashboard'>
          <Button>Dashboard</Button>
        </RouterLink> */}
			</div>
			<FooterContainer />
		</div>
	);
};

export const getStaticProps = async ({ locale }) => ({
	props: {
		...(await serverSideTranslations(locale, ["header", "common"])),
	},
});

export default ErrorPage;
